import React from "react"
import { BlockQuote } from "../../components/BlockQuote";

export default function QuotePage() {
  return <div>
    <Test title='Single line'>single line</Test>
    <Test title='Multi line'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.</Test>
    <Test title='Multi line with paragraphs'><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.</p></Test>
    <Test title='Multiple paragraphs'><p>First paragraph</p><p>Second paragraph</p></Test>
    <Test title='Single word'>single-word</Test>
    <Test title='Formatted content'><h2>Heading</h2></Test>
    <Test title='Formatted content in paragraph'><p><span className='text-3xl'>Lorem ipsum dolor sit amet</span>, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.</p></Test>
    <Test title='Single letter'>X</Test>
  </div>
}

function Test({title, children}) {
  return <>
    <h1>{title}</h1>
    <div className='bg-gray-200'>
      <BlockQuote>{children}</BlockQuote>
    </div>
  </>
}
