import React from "react";

export function BlockQuote({ children }) {
  if (!children)
    return null;

  return <div data-testid="block-quote" className="ml-4 italic">
    <Open><Close>{children}</Close></Open>
  </div>;
}

function Open({ children }) {
  return React.Children.map(children, (child, i) => i === 0 ? prependQuote(child) : child);

  function prependQuote(child) {
    if (typeof child === 'string')
      return enquote(child)
    if (typeof child.props?.children === 'string')
      return copyWithChildren(child, enquote(child.props.children))
    if (Array.isArray(child))
      return [prependQuote(child[0]), ...child.slice(1)]
    if (Array.isArray(child.props?.children))
      return copyWithChildren(child, [prependQuote(child.props.children[0]), ...child.props.children.slice(1)])
    return copyWithChildren(child, prependQuote(child.props.children))
  }

  function enquote(child) {
    return <>
      <span data-testid="open-quote" className="relative z-10">
        <Quote left>&#x201C;</Quote>
        {child.slice(0, 2)}
      </span>
      {child.slice(2)}
    </>;
  }
}

function Close({ children }) {
  const lastChild = React.Children.count(children) - 1;
  return React.Children.map(children, (child, i) => i === lastChild ? appendQuote(child) : child);

  function appendQuote(child) {
    if (typeof child === 'string')
      return enquote(child)
    if (typeof child.props?.children === 'string')
      return copyWithChildren(child, enquote(child.props.children))
    if (Array.isArray(child))
      return [...child.slice(0, -1), appendQuote(child.slice(-1)[0])]
    if (Array.isArray(child.props?.children))
      return copyWithChildren(child, [...child.props.children.slice(0, -1), appendQuote(child.props.children.slice(-1)[0])])
    return copyWithChildren(child, appendQuote(child.props.children))
  }

  function enquote(child) {
    return <>
      {child.slice(0, -2)}
      <span data-testid="close-quote" className="relative z-10">
        {child.slice(-2)}
        <Quote right>&#x201D;</Quote>
      </span>
    </>;
  }
}

function Quote({ right, children }) {
  const style = {
    fontSize: "1.75em",
    lineHeight: "2em",
  }

  return <span data-testid="quote"
               className="absolute bottom -z-10 text-primary-200 leading-none font-bold non-italic"
               style={{
                 ...style,
                 [right ? "right": "left"]: "-0.2em",
               }}>{children}</span>;
}

function copyWithChildren(parent, children) {
  return {
    ...parent,
    props: {
      ...parent.props,
      children: children
    }
  }
}